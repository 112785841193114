import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Masonry from "../../components/masonry"
import { graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="restoration" />

    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-spaced is-size-3">restoration</h1>
          <h3 className="is-size-5">
            Memories are very precious and need to be preserved. At Tableland
            Photography the appraisal, scanning, restoration and archival printing is all
            handled locally in the Studio at Malanda. Restored prints are
            finally reproduced on archival fibre based papers which are
            guaranteed against fading or decolourisation for 75 years.
          </h3>
        </div>
      </div>
    </section>
    <section>
      <Masonry
        items={data.restoration.edges.map(({ node }) => ({
          image: { ...node.childImageSharp.fluid },
          publicURL: node.publicURL,
          name: node.name,
        }))}
        itemsPerRow={[1, 5]}
      />
    </section>
  </Layout>
)

export const data = graphql`
  query {
    restoration: allFile(
      filter: { relativeDirectory: { eq: "gallery/restoration" } }
    ) {
      edges {
        node {
          id
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
